import * as React from "react"
import { Link, useStaticQuery } from "gatsby"
import '../styles/components/Button.css'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { ErrorMessage, Formik } from "formik";
import "../styles/components/ContactForm.css"
import Button from '../components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import * as Yup from 'yup';

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const ContactFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'That name is way too short!')
    .max(50, 'That name is a little bit too long.')
    .required('We need to know what to call you!'),
  email: Yup.string().email('Invalid email').required('We require an email address to contact you with!'),
  message: Yup.string()
    .min(10, 'That message is a bit short, try a few more characters!')
    .required('A message is required, otherwise how will we know what you want?'),
  });

const ContactForm = ({link, anchor, children}) => {
  
  const [submitted, setSubmitted] = React.useState(false);
  return(
    <Formik  
      initialValues={{ name: '',
                       email: '',
                       phone: '',
                       message: ''
                     }}
      validationSchema={ContactFormSchema}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        setTimeout(() => {
          fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact-form", ...values })
          }).then(() => {
            actions.setSubmitting(false);
            actions.setStatus(true);
            actions.resetForm({status: true});
            setSubmitted(true);
          })

        }, 1000);
      
    }}>
      {formik => (
        
        <form class="form" onSubmit={formik.handleSubmit} data-netlify={true} name="contact-form">

          <p type="Name:"><input name="name" placeholder="What should we call you?" {...formik.getFieldProps('name')} /></p>
          {formik.touched.name && formik.errors.name && <span className="error"><FontAwesomeIcon icon={faExclamationCircle} size="xl" color="rgb(255, 0, 0)"/> {formik.errors.name}</span>}
          
          <p type="Email:"><input name="email" placeholder="Don't worry we won't spam you" {...formik.getFieldProps('email')} /></p>
          {formik.touched.email && formik.errors.email && <span className="error"><FontAwesomeIcon icon={faExclamationCircle} size="xl" color="rgb(255, 0, 0)"/> {formik.errors.email}</span>}
          
          <p type="Phone:"><input name="phone" placeholder="We just want to hear your voice" {...formik.getFieldProps('phone')}></input></p>
          
          <p type="Message:"><input name="message" placeholder="What's on your mind'?" {...formik.getFieldProps('message')}></input></p>
          {formik.touched.message && formik.errors.message && <span className="error"> <FontAwesomeIcon icon={faExclamationCircle} size="xl" color="rgb(255, 0, 0)"/> {formik.errors.message}</span>}
          
          <div className={`submit-row ${submitted ? 'submitted' : ''}`}>
            {formik.status && <div>Thanks for your message, we'll be in touch very soon!</div>}
            <div>{formik.isSubmitting && <FontAwesomeIcon icon={faCircleNotch} size="2x" color="rgb(36, 110, 185)" spin/>}
              <Button submit={true} handleSubmit={formik.submitForm}>Send</Button>
            </div>
          </div>
        </form>
        )}
      
    </Formik>
  )
}



export default ContactForm
