import * as React from "react"
import { Link } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import CallToAction from "../components/CallToAction"
import Heading from "../components/Heading"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPhone,
  faMapSigns,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons"
import ContactForm from "../components/ContactForm"
import GoogleMapReact from "google-map-react"
import "../styles/contact.css"
import Layout from "../components/Layout.js"
import Seo from "../components/Seo.js"
import { graphql } from "gatsby"

function createMapOptions(maps) {
  return {
    zoomControl: false,
    scaleControl: false,
    fullscreenControl: false,
  }
}

const ContactPage = ({ data }) => {
  let page = data.allPrismicContactUs.edges[0].node.data

  const location = {
    address: "Rocky Mountain Web",
    lat: 51.18400923294181,
    lng: -115.56544593283837,
  }
  const zoomLevel = 14

  return (
    <Layout>
      <Seo
        title="Contact Us"
        description="We're always looking for opportunities to work with ambitious people, so we'd love to hear from you. Get in contact online today or give us a call."
      />
      <Heading>How Can We Help?</Heading>
      <section className="inset">
        <ContactForm />
      </section>

      <div class="shaded">
        <Heading>Our Details</Heading>
        <section className="inset split thirds">
          <div>
            <h4>
              <strong>Rocky Mountain Web</strong>
            </h4>
            <ul class="contact-details">
              <li>
                <div>
                  <FontAwesomeIcon
                    icon={faMapSigns}
                    size="xl"
                    color="rgb(36, 110, 185)"
                  />
                </div>
                <div>Box 1837, Banff AB, Canada</div>
              </li>
              <li>
                <div>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    size="xl"
                    color="rgb(36, 110, 185)"
                  />
                </div>
                <div>
                  <a href="mailto:info@rockymountainweb.ca">
                    info@rockymountainweb.ca
                  </a>
                </div>
              </li>
              <li>
                <div>
                  <FontAwesomeIcon
                    icon={faPhone}
                    size="xl"
                    color="rgb(36, 110, 185)"
                  />
                </div>
                <div>
                  <a href="tel:1800 323 4752">1800 323 4752</a>
                </div>
              </li>
            </ul>
          </div>
          <div className="text-center">
            <GatsbyImage
              image={getImage(
                page.owner_image.localFile.childImageSharp.gatsbyImageData
              )}
              alt="hello"
            />
          </div>
          <div id="map">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: `AIzaSyBDovFOEEUYwQ_lTDiJZzWN-9ezUR6L82w`,
              }}
              defaultCenter={location}
              defaultZoom={zoomLevel}
              controls="false"
              options={createMapOptions}
            >
              <FontAwesomeIcon
                icon={faMapSigns}
                size="2x"
                color="rgb(36, 110, 185)"
                lat={location.lat}
                lng={location.lng}
                text={location.address}
              />
            </GoogleMapReact>
          </div>
        </section>
      </div>

      <CallToAction
        heading="Our Work"
        body="We get it, choosing a trusted web agency is a big decision. Why not take a look at some of the work we've done recently first."
        buttonText="Our Work"
        bgColor="rgb(36, 110, 185)"
        link="our-work"
      />
    </Layout>
  )
}

export const query = graphql`
  query ContactPage {
    allPrismicContactUs {
      edges {
        node {
          data {
            owner_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 200
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
  }
`
export default ContactPage
